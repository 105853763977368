import React, { Component } from "react";
import ReactGA from "react-ga4";
import "./App.css";
import Header from "./Components/Header";
// import Footer from "./Components/Footer";
// import About from "./Components/About";
// import Contact from "./Components/Contact";
// import TwitchStream from "./Components/TwitchStream";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: "bar",
      resumeData: {},
    };

    ReactGA.initialize("UA-174682564-1 ");
    ReactGA.send({hitType: "pageview", page: window.location.pathname})
  }

  render() {
    return (
      <div className="App">
        <Header />
      </div>
    );
  }
}

export default App;

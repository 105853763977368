import React, { useEffect, useRef } from "react";

export const Header = () => {
  const bannerTextRef = useRef(null)

    const social = [
    {
      name: "email",
      url: "mailto://luther@lutherminshull.com",
      className: "far fa-envelope",
    },
    {
      name: 'signal',
      url: 'https://signal.me/#eu/9D5QyFjUiyj2JjD70iZaTUUUlcDlCS5gypNdmr8uLRhDz9kAuJRDVLrYMcHnuzI3',
      className: "fa-brands fa-signal-messenger"
    },
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/luther-minshull-61b6581aa/",
      className: "fa fa-linkedin",
    },
    {
      name: "instagram",
      url: "http://instagram.com/luther.minshull",
      className: "fa fa-instagram",
    },
    {
      name: "github",
      url: "https://github.com/2sBs",
      className: "fa fa-github",
    },
    {
      name: "twitter",
      url: "http://twitter.com/lutherminshull",
      className: "fa fa-twitter",
    },
  ];

  const networks = social.map(function (network) {
    return (
      <li key={network.name}>
        <a href={network.url}>
          <i className={network.className}></i>
        </a>
      </li>
    );
  });

  return (
    <header id="home">
      <div className="row banner">
        <div className="banner-text" ref={bannerTextRef}>
          <h1 className="responsive-headline">Hi, I'm Luther Minshull.</h1>
          <h3>
            I'm a{" "}
            <span>
              <i>Computer Science student </i>
            </span>
            at{" "}
            <span>
              <i> QUT</i>
            </span>
            , with a passion for <span>software development</span>,{" "}
            <span>esports</span> and <span>live production</span> based in
            Brisbane, Australia.
          </h3>
          <hr />
          <ul className="social">{networks}</ul>
        </div>
      </div>
    </header>
  );
}


export default Header;
